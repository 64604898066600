/* eslint-disable*/
import VueStoreService from './VueStoreService';
const ScheduleService = {
    async getSchedules(filter,store){
        var resp = await VueStoreService.get("job-scheduler"+filter, store)
        return resp;
    },
    async saveSchedule(data,store){
        var resp = await VueStoreService.post("job-scheduler",data,store)
        return resp;
    },
    async getScheduleById(schedule_id,store){
        var resp = await VueStoreService.get( "job-scheduler/"+schedule_id,store)
        return resp;
    },
    async updateSchedule(schedule_id,data,store){
        var resp = await VueStoreService.put("job-scheduler/"+schedule_id,data,store)
        return resp;
    },

    async deleteSchedule(schedule_id,store){
        var resp = await VueStoreService.delete("job-scheduler/"+schedule_id,store)
        return resp;
    },
}

export default ScheduleService;